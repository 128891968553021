import '@total-typescript/ts-reset'

import { createApp as createVueApp, h, type App } from 'vue'
import { createInertiaApp, type CreateInertiaAppProps } from '@shared/inertia'
import '@shared/axios'
import { initSentry } from '@shared/sentry'
import { initTracking } from '@shared/tracking'

export type SetupFn = (app: App) => void
export type InertiaOptions = Omit<CreateInertiaAppProps, 'resolve' | 'setup'>

export type AppName =
  | 'admin'
  | 'consumer'
  | 'consumer_seo'
  | 'corporate'
  | 'corporate_public'
  | 'internal'
  | 'redemption'

export function createApp (
  name: AppName,
  pages: Record<string, any>,
  setup?: SetupFn,
  options?: { createAppWith?: typeof createVueApp },
) {
  return createInertiaApp({
    progress: false,
    async resolve (pageName: string) {
      const page = pages[`./${pageName}.vue`]

      if (!page) {
        throw new Error(`
          Unknown page ${pageName}.

          Is it located under app/frontend/${name}/pages with a .vue extension?
        `)
      }

      return import.meta.env.SSR || typeof page !== 'function'
        ? page
        : await page()
    },
    setup ({ plugin, App: InertiaApp, props, el }) {
      const createApp = options?.createAppWith || createVueApp
      const app = createApp({ name, render: () => h(InertiaApp, props) })
        .use(plugin)

      setup?.(app)

      initSentry(app, props)

      if (name !== 'admin')
        initTracking(name)

      if (el)
        app.mount(el)

      return app
    },
    ...options,
  })
}
