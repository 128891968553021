import { createSSRApp as createAppWith } from 'vue'
import { createApp } from '@shared/app'
import { maska } from 'maska'
import { createPinia } from 'pinia'
import 'virtual:svg-icons-register'
import 'windi.css'
import 'virtual:windi-devtools'
import '@consumer/styles/global.scss'
import pages from '@consumer/pages'
import PortalVue from 'portal-vue'
import FloatingVue, { Tooltip } from 'floating-vue'
import '@shared/timeZone'

const pinia = createPinia()

if (document.getElementById('app')) {
  createApp('consumer', pages, (app) => {
    app
      .use(pinia)
      .directive('mask', maska)
      .component('GTooltip', Tooltip)
      .use(FloatingVue, {
        themes: {
          'top-nav-dropdown': { $extend: 'dropdown' },
        },
      })
      .use(PortalVue)
  }, { createAppWith })
}
